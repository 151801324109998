<template>
  <div class="mt-5">
    <v-list-item>
      <v-select
        label="Select Ledger's Ranking Type"
        outlined
        placeholder="Exam"
        dense
        :items="ledgerRank"
        class="pa-0"
        v-model="ledger_rank"
      />
    </v-list-item>
    <div>
      <v-col md="4">
        <v-select
          label="Basic"
          outlined
          placeholder="Basic"
          dense
          :items="marksheet_type"
          class="pa-0"
          v-model="basic_marksheet_type"
        />
      </v-col>
      <v-col md="4">
        <v-select
          label="Pre Basic"
          outlined
          placeholder="Pre Basic"
          dense
          :items="marksheet_type"
          class="pa-0"
          v-model="prebasic_marksheet_type"
        />
      </v-col>
      <v-col md="4">
        <v-select
          label="Secondary"
          outlined
          placeholder="Secondary"
          dense
          :items="marksheet_type"
          class="pa-0"
          v-model="secondary_marksheet_type"
        />
      </v-col>
    </div>
    <v-list-item>
      <v-checkbox
        style="margin-right: 20px"
        color="blue"
        v-model="result_block"
      >
        <template v-slot:label>
          <div style="color: #333">
            Block Results
            <p style="line-height: 10px">
              <small style="color: #777; font-size: 12px; font-weight: normal">
                Student's having their invoice pending is deprived from results.
              </small>
            </p>
          </div>
        </template>
      </v-checkbox>
    </v-list-item>
    <v-list-item>
      <v-checkbox
        style="margin-right: 20px"
        color="blue"
        v-model="advance_practical_mode"
      >
        <template v-slot:label>
          <div style="color: #333">
            Continuous Assessment System (CAS)
            <p style="line-height: 10px">
              <small style="color: #777; font-size: 12px; font-weight: normal">
                Divide practical marks into multiple sub categories.
              </small>
            </p>
          </div>
        </template>
      </v-checkbox>
    </v-list-item>
    <!-- <v-list-item>
      <v-checkbox
        style="margin-right: 20px"
        color="blue"
        v-model="gp_custom_calculation"
      >
        <template v-slot:label>
          <div style="color: #333">
            GP Custom Calculation 
            <p style="line-height: 10px">
              <small style="color: #777; font-size: 12px; font-weight: normal">
                GP calculation is based on the Obtained marks divided by 25.
              </small>
            </p>
          </div>
        </template>
      </v-checkbox>
    </v-list-item> -->
    <v-list-item>
      <v-checkbox
        style="margin-right: 20px"
        color="blue"
        v-model="cdc_based_gpa"
      >
        <template v-slot:label>
          <div style="color: #333">
            CDC Based GPA
            <p style="line-height: 10px">
              <small style="color: #777; font-size: 12px; font-weight: normal">
                Set GPA calculation mode based on valid CDC format.
              </small>
            </p>
          </div>
        </template>
      </v-checkbox>
    </v-list-item>
    <v-list-item></v-list-item>
    <v-list-item></v-list-item>
    <v-list-item></v-list-item>
    <v-list-item></v-list-item>
    <br />
    <v-list-item>
      <v-btn large block color="info outline" @click="examSubmit">Save</v-btn>
    </v-list-item>
  </div>
</template>
<script>
export default {
  data: () => ({
    ledgerRank: [
      { value: "both", text: "Both" },
      { value: "grade", text: "Grade" },
      { value: "section", text: "Section" },
    ],
    marksheetRank: [
      { value: "grade", text: "Grade" },
      { value: "section", text: "Section" },
    ],
    marksheet_type:[
      { value: "grading", text: "Grading" },
      { value: "numbering", text: "Numbering" },
    ],
    marksheet_rank: "",
    ledger_rank: "",
    result_block: false,
    advance_practical_mode:false,
    basic_marksheet_type:'',
    prebasic_marksheet_type:'',
    secondary_marksheet_type:'',
    gp_custom_calculation:false,
    cdc_based_gpa:false,

  }),
  mounted() {
    this.getExamSetting();
  },
  methods: {
    getExamSetting() {
      this.$rest.get("/api/exam-setting").then(({ data }) => {
        this.marksheet_rank = data.exam ? data.exam.marksheet_rank : "";
        this.ledger_rank = data.exam ? data.exam.ledger_rank : "";
         this.basic_marksheet_type = data.exam ? data.exam.marksheet_type.basic : "";
        this.prebasic_marksheet_type = data.exam ? data.exam.marksheet_type.prebasic : "";
        this.secondary_marksheet_type = data.exam ? data.exam.marksheet_type.secondary : "";
        this.result_block = data.exam
          ? data.exam.result_block === "true"
          : false;
        this.advance_practical_mode = data.exam
          ? data.exam.advance_practical_mode === "true"
          : false;
        this.gp_custom_calculation = data.exam
          ? data.exam.gp_custom_calculation === "true"
          : false;
        this.cdc_based_gpa = data.exam
          ? data.exam.cdc_based_gpa === "true"
          : false;
       
      });
    },
    examSubmit() {
      let formData = new FormData();
      formData.set("ledger_rank", this.ledger_rank);
      formData.set("result_block", this.result_block.toString());
      formData.set("advance_practical_mode", this.advance_practical_mode.toString());
      formData.set("gp_custom_calculation", this.gp_custom_calculation.toString());
      formData.set("cdc_based_gpa", this.cdc_based_gpa.toString());
      formData.set("marksheet_rank", this.marksheet_rank);
      formData.set("basic", this.basic_marksheet_type);
      formData.set("prebasic", this.prebasic_marksheet_type);
      formData.set("secondary", this.secondary_marksheet_type);

      formData.set("flag", "exam");

      this.$rest.post("/api/setting", formData).then(({ data }) => {
        this.$events.fire("notification", { message: data.message });
        this.getExamSetting();
      });
    },
  },
};
</script>
